<template>
  <div id="enrichproduct" class="container-fluid m-0 p-0">
    <div class="row m-0 px-3 justify-content-center">
      <div class="col-12 col-lg-12 m-0 p-0 shadow mb-3 bg-white rounded">
        <div class="row m-0 py-4 px-2">
          <p class="px-3 font-600 w-100" style="display: flex">
            สินค้า&nbsp;
            <span class="text-main">{{
              new Intl.NumberFormat().format(totalRows)
            }}</span>
            &nbsp;รายการ
            <span v-if="report === true" style="flex:9; text-align: right; white-space: nowrap;"><button
                class="btn btn-sm bt-DC" @click="exportReport()">
                นำออกรายงานการปฏิเสธ <span v-html="$svg_icon.excel"></span></button></span>
            <span style="flex: 1; text-align: right; white-space: nowrap;"><button class="btn btn-sm bt-bnb"
                @click="$emit('export_excel')">
                นำออกข้อมูล <span v-html="$svg_icon.excel"></span></button></span>
          </p>
          <div class="col-12">
            <b-table :empty-text="'ไม่พบข้อมูล / No Data'" :fields="fields" :items="items" hover outlined show-empty
              responsive :busy="$store.getters['enrichproductActions/get_onload']" class="font-0-8s">
              <template #table-busy>
                <div class="text-center text-main my-2">
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(showOnWeb)="row">
                <p class="badge badge-pill font-500 badge-main m-0 py-1" v-if="String(row.item.showOnWeb).trim() == 'PWB'">
                  {{ row.item.showOnWeb }}
                </p>
                <!-- <p class="badge badge-pill font-500 badge-bnb m-0 py-1"
                  v-else-if="String(row.item.showOnWeb).trim() == 'BNB'">
                  {{ row.item.showOnWeb }}
                </p>
                <p class="badge badge-pill font-500 badge-main m-0 py-1"
                  v-else-if="String(row.item.showOnWeb).trim() == 'BNB|TWD'">
                  {{ row.item.showOnWeb }}
                </p> -->
                <p v-else>
                  {{ row.item.showOnWeb }}
                </p>
              </template>
              <template #cell(enrich)="row">
                <!-- {{ row.item }} -->
                <span v-if="
                  $serviceMain.chechnull(String(row.item.enrich).trim()) == ''
                ">
                  <div class="m-0 p-0 row w-100" v-if="
                    $serviceMain.chechnull(
                      String(row.item.WrStatus).trim()
                    ) !== '' ||
                    $serviceMain.chechnull(
                      String(row.item.GpStatus).trim()
                    ) !== ''
                  ">
                    <p class="m-0 p-0 w-100 ">
                      <span class="badge font-500  " :class="[
                        row.item.WrStatus == 'Y' ? 'badge-bnb' : 'badge-twd',
                      ]">CW</span>
                      <span class="badge font-500 " :class="[
                        row.item.GpStatus == 'Y' ? 'badge-bnb' : 'badge-twd',
                      ]">GD</span>
                    </p>
                    <!-- <p>
                      <span
                        :class="[
                          row.item.WrStatus == 'Y' ? 'text-bnb' : 'text-danger',
                        ]"
                        >Writer</span
                      >|
                      <span
                        :class="[
                          row.item.GpStatus == 'Y' ? 'text-bnb' : 'text-danger',
                        ]"
                        >Graphic</span
                      >
                    </p> -->
                  </div>
                </span>

                <span v-else>
                  <p class="badge   font-500 badge-main m-0 py-1  ">
                    {{ row.item.enrich }}
                  </p>
                </span>
              </template>
              <template #cell(action)="row">
                <span v-html="$svg_icon.edit" v-if="$roleaction('Edit Enrich Product')" type="button"
                  class="mr-3 text-muted" v-b-tooltip.hover="'Write Content'" @click="edit(row.item)"></span>

                <span v-html="$svg_icon.view" v-if="$roleaction('View Enrich Product')" type="button" class="text-main-h"
                  v-b-tooltip.hover="'View'" @click="view(row.item)"></span>
              </template>
            </b-table>
          </div>
          <div class="col-12">
            <div class="row justify-content-end">
              <div class="col-6 col-xl-3 input-group-prepend input-group-sm">
                <select class="custom-select" v-model="page_size" @change="changesize($event)">
                  <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                    {{ e }}
                  </option>
                </select>
              </div>
              <div class="col-6 col-xl-3">
                <b-pagination v-model="page_num" :total-rows="totalRows" :per-page="page_size" align="fill" size="sm"
                  class="my-0"></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import fileDownload from "js-file-download";
export default {
  name: "Tableproduct",
  data() {
    return {
      page_size: this.$store.getters["enrichproductActions/get_page_size"],
      page_size_list: [25, 50, 75, 100],
      page_num: this.$store.getters["enrichproductActions/get_page_num"],
      totalRows:
        this.$store.getters["enrichproductActions/get_dataproduct"].length == 0
          ? 0
          : this.$store.getters["enrichproductActions/get_dataproduct"][0]
            .total,

      fields: [
        { key: "skCode", label: "skCode" },
        { key: "catCode", label: "catCode" },
        { key: "catName", label: "Sub-Dept Name", thStyle: { width: "10%" } },
        { key: "prNameTH", label: "prNameTH" },
        { key: "showOnWeb", label: "showOnWeb", class: "text-center" },
        { key: "attr", label: "Special Attribute​", class: "text-center" },
        {
          key: "prsstatus",
          label: "สถานะ SKU​",
          class: "text-center",
        },
        { key: "createdate", label: "วันที่ลงทะเบียน​" },
        { key: "lastUpdateMMS", label: "วันที่แก้ไข(MMS)" },
        { key: "updateBy", label: "updateBy" },
        {
          key: "enrich",
          label: "enrich",
          class: "text-center",
          thStyle: { width: "8%" },
        },
        {
          key: "action",
          label: "",
          class: "text-center",
          thStyle: { width: "8%" },
        },
      ],
      items: this.$store.getters["enrichproductActions/get_dataproduct"],
      enrich: '',
      report: false
    };
  },
  watch: {
    page_num: function () {
      this.$store.dispatch("enrichproductActions/set_page_num", this.page_num);
      this.$emit("confirm");
    },
    get_page_size: function (v) {
      this.$store.dispatch("enrichproductActions/set_page_num", 1);
      this.page_size = v;
    },
    get_page_num: function (v) {
      this.page_num = v;
    },
    get_dataproduct: function (v) {
      this.items = v;
      this.totalRows = v.length == 0 ? 0 : v[0].total;
      this.report = v[0].enrich === 'R' && this.enrich === 'R' ? true : false
    },
    get_enrich: function (v) {
      this.enrich = v
    },
  },
  computed: {
    get_page_size: function () {
      return this.$store.getters["enrichproductActions/get_page_size"];
    },
    get_page_num: function () {
      return this.$store.getters["enrichproductActions/get_page_num"];
    },
    get_dataproduct: function () {
      return this.$store.getters["enrichproductActions/get_dataproduct"];
    },
    get_enrich: function () {
      return this.$store.getters["enrichproductActions/get_data"].enrich
    },
  },
  methods: {
    async exportReport() {
      let data = this.$store.getters["enrichproductActions/get_data"];
      data.lvl = this.$store.getters["categoryActions/get_category"].lvl;
      data.catcode = this.$store.getters[
        "categoryActions/get_category"
      ].catcode;
      data.RowsOfPage = this.$store.getters[
        "enrichproductActions/get_page_size"
      ];
      data.PageNumber = this.$store.getters[
        "enrichproductActions/get_page_num"
      ];

      data.excel = "Y";
      let getdata = await this.$serviceAPI.call_API_Download(
        "post",
        `report/getreportreject`,
        data,
        1
      );
      fileDownload(getdata.data, `RejectedReport.xlsx`);
      try {
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    changesize(e) {
      // e.target.value

      this.$store.dispatch(
        "enrichproductActions/set_page_size",
        e.target.value
      );
      this.$emit("confirm");
    },
    edit(e) {
      this.$router.push({
        name: "Enrich Product Detail",
        params: { skcode: e.skCode },
      });
    },
    view(e) {
      let routeData = this.$router.resolve({
        name: "view Product",
        params: { skcode: e.skCode },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style></style>
