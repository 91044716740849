<template>
  <b-modal
    id="Modalfindbycatcode"
    hide-footer
    no-close-on-backdrop
    size="xl"
    title="เลือกหมวดหมู่"
  >
    <div class="row">
      <div class="col-12">
        <div class="row border rounded">
          <div class="col-3 cat-height">
            <div class="m-0 p-0" v-if="cat_lv1.length > 0">
              <p
                type="button"
                class="cat m-0 p-2"
                :class="
                  e.catname == select.lv1.catname ? ' text-main font-600' : ''
                "
                v-for="(e, i) in cat_lv1"
                :key="i"
                @click="selectlv1(e)"
              >
                {{ e.catname }}
              </p>
            </div>
          </div>
          <div class="col-3 cat-height border">
            <div class="m-0 p-0" v-if="cat_lv2.length > 0">
              <p
                type="button"
                class="cat m-0 p-2"
                :class="
                  e.catname == select.lv2.catname ? ' text-main font-600' : ''
                "
                v-for="(e, i) in cat_lv2"
                :key="i + 'cat_lv2'"
                @click="selectlv2(e)"
              >
                {{ e.catname }}
              </p>
            </div>
          </div>
          <div class="col-3 cat-height border">
            <div class="m-0 p-0" v-if="cat_lv3.length > 0">
              <p
                type="button"
                class="cat m-0 p-2"
                :class="
                  e.catname == select.lv3.catname ? ' text-main font-600' : ''
                "
                v-for="(e, i) in cat_lv3"
                :key="i + 'cat_lv3'"
                @click="selectlv3(e)"
              >
                {{ e.catname }}
              </p>
            </div>
          </div>
          <div class="col-3 cat-height">
            <div class="m-0 p-0" v-if="cat_lv4.length > 0">
              <p
                type="button"
                class="cat m-0 p-2"
                :class="
                  e.catname == select.lv4.catname ? ' text-main font-600' : ''
                "
                v-for="(e, i) in cat_lv4"
                :key="i + 'cat_lv4'"
                @click="selectlv4(e)"
              >
                {{ e.catname }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-3">
        <p>
          ที่เลือกในปัจจุบัน :
          <span class=" text-main font-600"> {{ select.selectnow }}</span>
        </p>
      </div>
      <div class="col-12 mt-2 text-right">
        <button class="btn bt-main1 mx-1 px-4" @click="hide()">ยกเลิก</button>
        <button class="btn bt-main mx-1 px-4" @click="confirm()">ยืนยัน</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "Modalfindbycatcode",

  data() {
    return {
      role_name: "",
      cat_lv1: [],
      cat_lv2: [],
      cat_lv3: [],
      cat_lv4: [],
      select: {
        lv1: { catcode: "", catname: "" },
        lv2: { catcode: "", catname: "" },
        lv3: { catcode: "", catname: "" },
        lv4: { catcode: "", catname: "" },
        catcode: "",
        catname: "",
        lvl: "",
        selectnow: "คุณไม่ได้เลือกหมวดหมู่",
      },
    };
  },
  watch: {
    get_category: function(v) {
      this.setcatinit();
    },
    cat_lv1: function() {
      this.cat_lv1 = this.cat_lv1;
    },
    cat_lv2: function() {
      this.cat_lv2 = this.cat_lv2;
    },
    cat_lv3: function() {
      this.cat_lv3 = this.cat_lv3;
    },
    cat_lv4: function() {
      this.cat_lv4 = this.cat_lv4;
    },
    // select: {
    //   async handler(val) {
    //     this.select = val;
    //   },
    //   deep: true,
    // },
  },
  computed: {
    get_category: function() {
      return this.$store.getters["categoryActions/get_category"];
    },
  },
  mounted() {
    this.getcat_lv1();
  },
  methods: {
    async setcatinit() {
      let cat = this.$store.getters["categoryActions/get_category"];
      let arrt_cat = [];
      arrt_cat[0] = cat.lv1;
      arrt_cat[1] = cat.lv2;
      arrt_cat[2] = cat.lv3;
      arrt_cat[3] = cat.lv4;

      for await (var [i, v] of arrt_cat.entries()) {
        if (v.catcode !== "") {
          if (i == 0) {
            // lvl 1

            await this.selectlv1(v);

            // console.log(i);
            // this.select.lv1 = v;
            // this.getcat_lv2(v);
            // this.select.selectnow = `${this.select.lv1.catname}`;
          } else if (i == 1) {
            // lvl 2

            await this.selectlv2(v);

            // console.log(i);
            // this.select.lv2 = v;
            // this.getcat_lv3(v);
            // this.select.selectnow = `${this.select.lv1.catname} > ${this.select.lv2.catname}`;
          } else if (i == 2) {
            // lvl 3

            await this.selectlv3(v);

            // console.log(i);
            // this.select.lv3 = v;
            // this.getcat_lv4(v);
            // this.select.selectnow = `${this.select.lv1.catname} > ${this.select.lv2.catname} > ${this.select.lv3.catname}`;
          } else if (i == 3) {
            // lvl 4
            await this.selectlv4(v);
            // console.log(i);
            // this.select.lv4 = v;
            // this.select.selectnow = `${this.select.lv1.catname} > ${this.select.lv2.catname} > ${this.select.lv3.catname} > ${this.select.lv4.catname}`;
          }
        }
      }
    },
    confirm() {
      this.$store.dispatch("categoryActions/setcategory", this.select);
      this.hide();
    },

    hide() {
      this.$bvModal.hide("Modalfindbycatcode");
    },
    async selectlv1(e) {
      return new Promise(async (resolve, reject) => {
        this.cat_lv2 = [];
        this.cat_lv3 = [];
        this.cat_lv4 = [];
        this.select.catcode = e.catcode;
        this.select.catname = e.catname;
        this.select.lvl = 1;
        this.select.lv1 = { catcode: e.catcode, catname: e.catname };
        this.select.lv2 = { catcode: "", catname: "" };
        this.select.lv3 = { catcode: "", catname: "" };
        this.select.lv4 = { catcode: "", catname: "" };
        this.select.selectnow = `${this.select.lv1.catname}`;

        await this.getcat_lv2(e);

        resolve("OK");
      });
    },
    async selectlv2(e) {
      return new Promise(async (resolve, reject) => {
        this.cat_lv3 = [];
        this.cat_lv4 = [];
        this.select.catcode = e.catcode;
        this.select.catname = e.catname;
        this.select.lvl = 2;
        this.select.lv2 = { catcode: e.catcode, catname: e.catname };
        this.select.lv3 = { catcode: "", catname: "" };
        this.select.lv4 = { catcode: "", catname: "" };
        this.select.selectnow = `${this.select.lv1.catname} > ${this.select.lv2.catname}`;

        await this.getcat_lv3(e);

        resolve("OK");
      });
    },
    async selectlv3(e) {
      return new Promise(async (resolve, reject) => {
        this.cat_lv4 = [];
        this.select.catcode = e.catcode;
        this.select.catname = e.catname;
        this.select.lvl = 3;
        this.select.lv3 = { catcode: e.catcode, catname: e.catname };
        this.select.lv4 = { catcode: "", catname: "" };
        this.select.selectnow = `${this.select.lv1.catname} > ${this.select.lv2.catname} > ${this.select.lv3.catname}`;
        await this.getcat_lv4(e);

        resolve("OK");
      });
    },
    async selectlv4(e) {
      return new Promise(async (resolve, reject) => {
        this.select.catcode = e.catcode;
        this.select.catname = e.catname;
        this.select.lvl = 4;
        this.select.lv4 = { catcode: e.catcode, catname: e.catname };
        this.select.selectnow = `${this.select.lv1.catname} > ${this.select.lv2.catname} > ${this.select.lv3.catname} > ${this.select.lv4.catname}`;

        resolve("OK");
      });

      // this.getcat_lv4(e);
    },
    async getcat(cat, lvl) {
      return new Promise(async (resolve, reject) => {
        try {
          let data = { level: lvl, catcode: cat };

          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `category/getpimcscategory`,
            data,
            1
          );

          resolve(getAPI.data);
        } catch (error) {
          this.$serviceMain.showErrorAlert(
            this,
            error.message == undefined ? error : error.message
          );
          reject(error);
        }
      });
    },
    async getcat_lv1() {
      this.cat_lv1 = await this.getcat("", 1);
      this.setcatinit();
    },
    async getcat_lv2(e) {
      this.cat_lv2 = await this.getcat(e.catcode, 2);
    },
    async getcat_lv3(e) {
      this.cat_lv3 = await this.getcat(e.catcode, 3);
    },
    async getcat_lv4(e) {
      this.cat_lv4 = await this.getcat(e.catcode, 4);
    },

    gen() {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < 10; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      let data = {
        catcode: Math.floor(Math.random() * 999999),
        catname: result,
      };
      return data;
    },
  },
};
</script>

<style scoped>
.cat-height {
  height: 55vh;
  overflow-y: auto;
}
</style>
