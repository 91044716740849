<template>
  <div id="enrichproduct" class="container-fluid m-0 p-0">
    <div class="row m-0 p-3 justify-content-center">
      <div class="col-12 col-lg-12 m-0 p-0 shadow   bg-white rounded">
        <div class="row m-0 p-4">
          <div class="col-12 mx-0 mb-3 mb-xl-0">
            <!-- <p class="font-1-2s font-600">Enrich Product</p> -->
          </div>
          <div class="col-12 col-lg-6">
            <div class="input-group input-group-sm mb-3">
              <div class="input-group-prepend input-group-sm">
                <select class="custom-select" v-model="data.mode">
                  <option v-for="(e, i) in select_mode" :key="i" :value="e.key">
                    {{ e.name }}
                  </option>
                </select>
              </div>

              <input
                type="search"
                class="form-control"
                placeholder="ค้นหา / Seach"
                v-model="data.find"
                v-on:keyup.enter="getuserole()"
              />
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="row form-inline mb-3">
              <div class="col-2 text-right">หมวดหมู่</div>
              <div class="col-10">
                <div class="input-group input-group-sm ">
                  <input
                    type="text"
                    class="form-control"
                    v-model="selectnow"
                    @click="CallModalfindbycatcode()"
                  />
                  <div class="input-group-append">
                    <button class="input-group-text" @click="Clearcatcode()">
                      x
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="row">
              <div class="col-12 col-lg-6">
                <div class="row form-inline mb-3">
                  <div class="col-6 text-right">วันที่เเก้ไขเริ่มต้น(MMS)</div>
                  <div class="col-6">
                    <input
                      type="date"
                      class="form-control form-control-sm w-100"
                      v-model="data.editbydate"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <div class="row form-inline mb-3">
                  <div class="col-6 text-right">วันที่เเก้ไขสิ้นสุด(MMS)</div>
                  <div class="col-6">
                    <input
                      type="date"
                      class="form-control form-control-sm w-100"
                      v-model="data.approvedbydate"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-12 col-lg-6">
            <div class="row form-inline mb-3">
              <div class="col-4 text-right">วันที่เเก้ไข</div>
              <div class="col-8">
                <input
                  type="date"
                  class="form-control form-control-sm w-100"
                  v-model="data.editbydate"
                />
              </div>
            </div>
          </div> -->

          <!-- <div class="col-12 col-lg-6">
            <div class="row form-inline mb-3">
              <div class="col-4 text-right">วันที่อนุมัติ</div>
              <div class="col-8">
                <input
                  type="date"
                  class="form-control form-control-sm w-100"
                  v-model="data.approvedbydate"
                />
              </div>
            </div>
          </div> -->
          <div class="col-12 col-lg-6">
            <div class="row form-inline mb-3">
              <div class="col-6 col-xl-4 text-right">เเสดงบนเว็บไซต์</div>
              <div class="col-6 col-xl-8">
                <div class="row">
                  <div class="form-check form-check-inline">
                    <input
                      id="showonweball"
                      class="form-check-input"
                      type="checkbox"
                      v-model="showonweball"
                      @change="selectall($event)"
                    />
                    <label class="form-check-label" for="showonweball"
                      >ALL</label
                    >
                  </div>
                  <div
                    class="form-check form-check-inline"
                    v-for="(e, i) in showonweb"
                    :key="i"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :id="i"
                      :value="e"
                      v-model="data.showonweb"
                    />
                    <label class="form-check-label" :for="i">{{
                      `${
                        e !== "''''"
                          ? String(e).replace(/'/g, "")
                          : "ไม่เเสดงบนเว็บไซต์"
                      }`
                    }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="row form-inline mb-3">
              <div class="col-6 col-xl-2 text-right">Enrich</div>
              <div class="col-6 col-xl-10  ">
                <select
                  class="form-control form-control-sm w-100"
                  v-model="data.enrich"
                >
                  <option value="">
                    เเสดงทั้งหมด
                  </option>
                  <option v-for="(e, i) in enrich" :key="i" :value="e.name">
                    {{ `${e.detail}(${e.name})` }}
                  </option>
                </select>
                <!-- <div
                  class="form-check form-check-inline"
                  v-for="(e, i) in enrich"
                  :key="i"
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    :value="e.key"
                    v-model="data.enrich"
                  />
                  <label class="form-check-label">{{ e.name }}</label>
                </div> -->
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="row form-inline mb-3">
              <div class="col-6 col-xl-4 text-right"></div>
              <div class="col-6 col-xl-8 row">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="writecontent"
                    v-model="data.writecontent"
                  />
                  <label class="form-check-label" for="writecontent"
                    >สินค้าเขียนคอนเทนต์</label
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <button
              type="button"
              class="btn bt-main btn-sm mx-1 px-4"
              @click="confirm()"
            >
              <span class="mx-1" v-html="$svg_icon.search"></span>ค้นหา
            </button>
            <button
              type="button"
              class="btn bt-main1 btn-sm mx-1 px-4"
              @click="redata()"
            >
              <span class="mx-1" v-html="$svg_icon.reset"></span>รีเซ็ต
            </button>
          </div>
        </div>
      </div>
    </div>
    <Tableproduct @confirm="confirm" @export_excel="export_excel" />
    <Modalfindbycatcode :key="reModalfindbycatcode" />
  </div>
</template>

<script>
import Modalfindbycatcode from "@/components/Products/EnrichProduct/Modalfindbycatcode.vue";
import Tableproduct from "../../components/Products/EnrichProduct/Tableproduct.vue";
import fileDownload from "js-file-download";
export default {
  name: "EnrichProduct",
  components: { Modalfindbycatcode, Tableproduct },
  data() {
    return {
      reModalfindbycatcode: Date.now(),
      showonweball: false,
      selectnow: this.$store.getters["categoryActions/get_category"].selectnow,
      data: {
        mode: "skucode",
        find: "",
        lvl: 1,
        excel: "N",
        catcode: "",
        showonweb: ["''PWB''"],
        showonwebconvert: "",
        enrich: "N",
        newproductnoly: false,
        editbydate: null,
        approvedbydate: null,
        writecontent: true,
      },

      showonweb: ["''PWB''", "''''"],
      enrich: [
        // { key: "A", name: "เตรียมส่ง Acommerce(A)" },
        // { key: "Y", name: "แก้ไขโดย PIM(Y)" },
        // { key: "N", name: "New Transfer" },
      ],
      select_mode: [
        { name: "ค้นหาชื่อสินค้า", key: "name" },
        { name: "ค้นหาด้วยเลข SKCode", key: "skucode" },
        { name: "ค้นหาด้วยสถานะ SKU", key: "status" },
        { name: "ค้นหาด้วย Brand", key: "brand" },
        { name: "ค้นหาด้วย Vendor", key: "vendor" },
        { name: "ค้นหาด้วย Attribute", key: "attr" },
        { name: "ค้นหาด้วย updateBy", key: "updateBy" },
      ],
    };
  },
  watch: {
    data: {
      async handler(val) {
        await this.$store.dispatch("enrichproductActions/setdata", val);
      },
      deep: true,
    },
    "data.showonweb": function() {
      this.showonweball =
        this.showonweb.length == this.data.showonweb.length ? true : false;
    },
    getcategory: function(v) {
      this.selectnow = v.selectnow;
      this.lvl = v.lvl;
      this.catcode = v.catcode;
    },
  },
  computed: {
    getcategory: function() {
      return this.$store.getters["categoryActions/get_category"];
    },
  },
  async mounted() {
    this.init();
    await this.getpimselectstatus();
    this.confirm();
  },
  methods: {
    init() {
      let data = this.$store.getters["enrichproductActions/get_data"];
      const {
        mode,
        find,
        lvl,
        excel,
        catcode,
        showonweb,
        showonwebconvert,
        enrich,
        newproductnoly,
        editbydate,
        approvedbydate,
        writecontent,
        RowsOfPage,
        PageNumber,
      } = data;

      this.data.mode = mode;
      this.data.find = find;
      this.data.lvl = lvl;
      this.data.excel = excel;
      this.data.catcode = catcode;
      this.data.showonweb = showonweb;
      this.data.showonwebconvert = showonwebconvert;
      this.data.enrich = enrich;
      this.data.newproductnoly = newproductnoly;
      this.data.editbydate = editbydate;
      this.data.approvedbydate = approvedbydate;
      this.data.writecontent = writecontent;
      this.data.RowsOfPage = RowsOfPage;
      this.data.PageNumber = PageNumber;
    },
    async getpimselectstatus() {
      return new Promise(async (resolve, reject) => {
        try {
          let getAPI = await this.$serviceAPI.call_API(
            "get",
            `select/getpimselectstatus`,
            {},
            1
          );

          this.enrich = getAPI.data;
          resolve("OK");
        } catch (error) {
          this.$serviceMain.showErrorAlert(
            this,
            error.message == undefined ? error : error.message
          );
          this.enrich = [];
          reject(error);
        }
      });
    },
    async export_excel() {
      let data = this.$store.getters["enrichproductActions/get_data"];
      data.lvl = this.$store.getters["categoryActions/get_category"].lvl;
      data.catcode = this.$store.getters[
        "categoryActions/get_category"
      ].catcode;
      data.RowsOfPage = this.$store.getters[
        "enrichproductActions/get_page_size"
      ];
      data.PageNumber = this.$store.getters[
        "enrichproductActions/get_page_num"
      ];

      data.excel = "Y";

      let getdata = await this.$serviceAPI.call_API_Download(
        "post",
        `report/getreportproductenrich`,
        data,
        1
      );
      fileDownload(getdata.data, `ProductInformationMangement.xlsx`);
      try {
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async confirm() {
      try {
        let data = this.$store.getters["enrichproductActions/get_data"];

        data.lvl = this.$store.getters["categoryActions/get_category"].lvl;
        data.catcode = this.$store.getters[
          "categoryActions/get_category"
        ].catcode;
        data.RowsOfPage = this.$store.getters[
          "enrichproductActions/get_page_size"
        ];
        data.PageNumber = this.$store.getters[
          "enrichproductActions/get_page_num"
        ];

        data.excel = "N";

        await this.$store.dispatch("enrichproductActions/get_product", data);
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    redata() {
      this.data = {
        mode: "skucode",
        find: "",
        lvl: 1,
        catcode: "",
        showonweb: [],
        showonwebconvert: "",
        enrich: "",
        newproductnoly: false,
        editbydate: null,
        approvedbydate: null,
        writecontent: false,
      };
      this.$store.dispatch("categoryActions/resetcategory");
    },
    selectall(e) {
      e.target.checked
        ? (this.data.showonweb = this.showonweb)
        : (this.data.showonweb = []);
    },
    CallModalfindbycatcode() {
      this.reModalfindbycatcode = Date.now();
      setTimeout(() => {
        this.$bvModal.show("Modalfindbycatcode");
      }, 100);
    },
    Clearcatcode() {
      this.selectnow = "";
      this.$store.dispatch("categoryActions/resetcategory");
    },
  },
};
</script>

<style></style>
